/* Button to show table of CIMD or MatClass */

.CimdBtn {
  background-color: #efefef;
  padding: 11px 16px;
  height: 40px;
  width: 100px;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.CimdBtn:hover {
  background-color: #dadada;
  padding: 11px 16px;
  height: 40px;
  width: 100px;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.MatClassBtn {
  background-color: #efefef;
  padding: 11px 16px;
  height: 40px;
  width: 150px;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.MatClassBtn:hover {
  background-color: #dadada;
  padding: 11px 16px;
  height: 40px;
  width: 150px;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

/* Table of CIMD or MatClass */
.matForm {
  margin-top: 20px;
}

.CIMD-MatClass-div {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #efefef;
  width: 550px;
  margin: 20px auto;
}

.CIMD-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.materialNav {
  display: flex;
  justify-content: center; /* Centrera innehållet horisontellt */
  padding: 0;
  margin: 0;
}

table {
  border-collapse: collapse;
  width: 550px;
}

th,
td {
  text-align: left;
  padding: 5px;
}

td {
  /* cursor: pointer; */
  width: fit-content;
}

.tdName {
  width: 100%;
}

/* tr {
    display: flex;
} */

tr:nth-child(even) {
  background-color: #efefef;
}

th {
  background-color: #c3c3c3;
  color: black;
}

.Cimd-MatClass-Update {
  background-color: #428bca;
  height: 30px;
  width: 90px;
  color: white;
  font-size: 16px;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  /* margin-right: -50px; */
}

.Cimd-MatClass-Update:hover {
  background-color: #428bca;
  height: 30px;
  width: 90px;
  color: white;
  font-size: 16px;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.Cimd-MatClass-Delete {
  background-color: #ca4247;
  height: 30px;
  width: 90px;
  color: white;
  font-size: 16px;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  /* margin-right: -50px; */
}

.Cimd-MatClass-Delete:hover {
  background-color: #ca4247;
  height: 30px;
  width: 90px;
  color: white;
  font-size: 16px;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

/* Create button for CIMD and MatClass */
.createCimdMatClass {
  background-color: #2cce5c;
  padding: 11px 16px;
  height: 40px;
  width: 550px;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-top: 18px;
}
.createCimdMatClass:hover {
  /* background-color: #2cce5c; */
  padding: 11px 16px;
  height: 40px;
  width: 550px;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 18px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
}
