.form {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 2em auto;
  max-width: 600px;
  padding: 15px 60px 50px 60px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.btn-container {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  margin-left: 30%;
  margin-bottom: 2%;
}
.btn-container button{
    width: 9em;
}
.btn-container button:hover{
    width: 9em;
}
.p-underline {
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 0px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.DetailLabel {
  color: #3d3d3d;
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
  margin-top: 5px;
}

.Cimd-MatClass {
  margin: 1em;
  display: flex;
  justify-content: space-evenly;
}

/* Image */
.DetailImage {
  /* margin-top: -10px;
    margin-left: -350px;
    margin-bottom: 10px; */
  height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
}

/* SampleName */
.DetailInput {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  margin: 8px 0;
  font-size: 14px;
  text-align: left;
  margin-bottom: 20px;
}

/* Textarea */

.DetailTextArea {
  width: 480px;
  height: 100px;
  resize: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  margin: 8px 0;
  font-size: 14px;
  text-align: left;
  margin-bottom: 20px;
}
