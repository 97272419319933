* {
  box-sizing: border-box;
}

.loginsignup h1 {
  font-weight: bold;
  margin: 0;
}

.loginsignup h2 {
  text-align: center;
}

.loginsignup p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.loginsignup span {
  font-size: 12px;
}

.loginsignup a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.loginsignup button {
  border-radius: 20px;
  border: 1px solid;
  background-image: linear-gradient(
    to left bottom,
    #05041c,
    #090a26,
    #091031,
    #06143c,
    #041747,
    #041746,
    #051745,
    #051744,
    #081436,
    #0a0f29,
    #07091c,
    #01020d
  );
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.loginsignup button:active {
  transform: scale(0.95);
}

.loginsignup button:focus {
  outline: none;
}

.loginsignup button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.loginsignup form {
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
  margin: 0;
}

.loginsignup input {
  background-color: #b4b4b6;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  border-radius: 10px;
}

.loginsignup {
  position: fixed;
  z-index: 1;
  left: 0;
  width: 50vw;
  height: 70vh;
  overflow: auto;
  font-family: "Montserrat", sans-serif;
  border-radius: 20px;
  margin-left: 30%;
  margin-top: 4%;
  box-shadow: 0 56px 112px rgba(0, 0, 0, 0.25), 0 40px 40px rgba(0, 0, 0, 0.22);
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 40%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.loginsignup.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  opacity: 0;
  width: 50%;
  z-index: 1;
}

.loginsignup.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.loginsignup.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background-image: linear-gradient(
    to left bottom,
    #05041c,
    #090a26,
    #091031,
    #06143c,
    #041747,
    #041746,
    #051745,
    #051744,
    #081436,
    #0a0f29,
    #07091c,
    #01020d
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.loginsignup.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.loginsignup.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.loginsignup.right-panel-active .overlay-right {
  transform: translateX(20%);
}
