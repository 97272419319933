.title {
  text-align: center;
}

.undertitle {
  text-align: center;
  margin-bottom: 30px;
}

label {
  color: #3d3d3d;
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

form {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 2em auto;
  max-width: 600px;
  padding: 15px 60px 50px 60px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.textInput {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  margin: 8px 0;
}

.p-underline {
  margin-top: 35px;
  margin-bottom: 35px;
  border-bottom: 1.5px solid lightgray;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

/* CIMD */

.cimdBox {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  margin: 8px 0px;
}

/* Flexcontainer row 1*/

.flex-container {
  display: flex;
}

.materialBox {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 20em;
  margin: 8px 0;
  flex: 1;
}

.labelAlloyBox {
  /* margin-left: 58px; */
}

.alloyBox:first-child {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  margin: 8px 0px;
}

/* Flexcontainer row 2*/

.prodBox {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 20em;
  margin: 8px 0;
  flex: 1;
}

.labelMagBox {
  margin-left: 5em;
}

.magBox:first-child {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  margin: 8px 0px;
}

/* Textarea */

.textareaBox {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 480px;
  height: 100px;
  margin: 8px 0px;
  resize: none;
}

/* Button */

.uploadBtn {
  background-color: #2cce5c;
  padding: 11px 16px;
  height: 40px;
  width: 100%;
  color: black;
  font-weight: bold;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-top: 10px;
}

.uploadBtn:hover {
  /* background-color: #d5d5d5; */
  padding: 11px 16px;
  height: 40px;
  width: 100%;
  color: black;
  font-weight: bold;
  /* border: 1.3px solid rgb(168, 167, 167); */
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.2);
}
