.nav-title {
  color: #f1f1f1;
  text-decoration: underline;
  margin-top: 10px;
  margin-bottom: 2em;
  font-size: 2em;
}

.nav-side {
  height: 100%;
  width: 11em;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  background-color: #111;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
}

ul {
  padding-inline-start: 0;
  margin-bottom: 20em;
}

.nav-side a {
  padding: 14px 16px;
  margin-top: auto;
  text-decoration: none;
  font-size: 17px;
  color: #818181;
  display: block;
  list-style: none;
  text-align: center;
  border: 1px solid transparent;
  font-weight: bold;
  color: #ababab;
}

.nav-side a:hover {
  color: #f1f1f1;
  border: 1px solid transparent;
  /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4); */
  text-shadow: 0px 0px 20px rgba(255, 165, 0, 0.8),
    0px 0px 40px rgba(255, 201, 14, 0.6), 0px 0px 80px rgba(255, 241, 89, 0.4);
}

/* Sign Out button */
.signOutBtn {
  background-color: transparent;
  color: rgb(205, 0, 0);
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 0;
  cursor: pointer;
  border: 1px solid transparent;
}
.signOutBtn:hover {
  background-color: transparent;
  text-shadow: 0 0 2px #d20101, 0 0 3px red, 0 0 25px red;
  font-weight: bold;
  font-size: 25px;
  cursor: pointer;
  /* border: 1px solid transparent; */
}

.loginBtn {
  background-color: transparent;
  color: lime;
  font-weight: bold;
  font-size: 25px;
  cursor: pointer;
  border: 1px solid transparent;
}
.loginBtn:hover {
  background-color: transparent;
  text-shadow: 0 0 200px lime, 0 0 15px lime, 0 0 55px lime;
  font-weight: bold;
  font-size: 25px;
  cursor: pointer;
  border: 1px solid transparent;
}

.button-container {
  position: absolute;
  bottom: 0;
  margin-bottom: 10em;
  width: 100%;
}

.hvBild-container {
  display: flex;
  align-items: flex-end;
  justify-content: start;
}

.hvBild-background {
  /* background-color: white; */
  bottom: 0;
  position: fixed;
  padding-left: 10px;
}
.hvBild {
  margin-bottom: 0;
  width: auto;
  height: 5em;
}
