* {
  box-sizing: border-box;
}


.undertitle {
  font-size: 18px;
  font-style: italic;
}

hr {
  display: none;
}

/* ViewMaterial - search/filter */
.filterbox {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 15px;
  width: 50%;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
  font-size: 16px;
}

/* ViewMaterial - FLEXBOX */
.materialContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2em 8em;
}

a {
  text-decoration: none;
  color: #333333;
}

.materialItem {
    display: flex;
    /* padding: 20px; */
    /* border: 1px solid lightgray; */
    border-radius: 5px;
    flex-direction: row;
    margin: 1em;
    width : 330px;
    height: 180px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2); 
}

.materialItem:hover {
    /* padding: 20px; */
    /* border: 1px solid lightgray; */
    border-radius: 5px;
    margin: 1em;
    width : 330px;
    height: 180px;
    cursor: pointer;
    box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.6);
}

.materialImage {
    aspect-ratio: 1/1;
    border-top-left-radius: 5px; /* Rundar översta vänstra hörnet */
    border-bottom-left-radius: 5px; /* Rundar nedersta vänstra hörnet */
}

.materialText {
    flex: 1;
    margin-left: 10px; /* marginalen för att lämna utrymme åt bilden */
    margin-right: 10px;
    font-weight: bold;
    text-align: left;
}

.material-Under {
    flex: 1;
    margin-left: 10px; /* marginalen för att lämna utrymme åt bilden */
    margin-right: 10px;
    font-size: 12px;
    text-align: left;
}
/* 
.material-ID {
    flex: 1;
    float: right;
    margin-right: 10px;
    font-size: 12px;
    align-self: flex-end;
    margin-top: 2em;
} */
