.home-undertitle {
  font-size: 18px;
  font-style: italic;
}

.Home-body {
  margin-left: 11em;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.card-title {
  margin-left: 150px;
  margin-top: -95px;
  text-align: left;
}

.card-p {
  margin-left: 150px;
  margin-top: -15px;
  text-align: left;
  font-size: 16px;
}

.number {
  border-radius: 100%;
  background-color: aliceblue;
  inline-size: 103px;
  padding: 20px;
  margin-top: -5px;
  color: black;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
}

.card-item {
  background-color: #eeeeee;
  padding: 25px;
  color: #333333;
  border: 1px solid #efefef;
  border-radius: 20px;
  margin-top: 30px;
  height: 110px;
  width: 50em;
  cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-item:hover {
  background-color: #d6d6d6;
  padding: 25px;
  color: #333333;
  border: 2px solid transparent;
  border-radius: 20px;
  margin-top: 30px;
  height: 110px;
  width: 52em;
  cursor: pointer;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
}
  
  